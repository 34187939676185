<template>
  <div
    ref="containerRef"
    class="swatches-wrapper"
  >
    <ul 
      v-if="!isExpanded"
      class="swatches list-reset product-item__swatches js-item-swatches"
    >
      <!-- Always visible swatches -->
      <li
        v-for="(color) in visibleSwatches"
        :key="color.color"
        class="swatches__item js-product-swatch-wrapper"
      >
        <button
          class="swatches__btn btn-icon js-product-swatch product-item__swatch"
          :class="{ 'is-selected': color.color === activeColor.color }"
          :data-color="color.color"
          :data-image="color.featured_image?.src || missingImage"
          :data-id="color?.featured_image?.variant_ids ? color?.featured_image?.variant_ids[0] : ''"
          :data-hover="color?.hoverAsset || ''"
          :data-title="color?.title || ''"
          type="button"
          :aria-pressed="[color.color === activeColor.color ? 'true': 'false']"
          @click="updateImage"
          @mouseenter="onSwatchHover($event)"
          @mouseleave="handleMouseLeave($event)"
        >
          <div
            class="swatch"
            :class="{
              'swatch--border': color?.color?.toLowerCase().includes('white')
            }"
          >
            <span
              v-if="color.type === 'image'"
            >
              <ResponsiveImage
                :image="color.value"
                :imageAlt="color.color"
                modifier="swatch"
              />
            </span>
            <div
              v-else
              class="ir ir--swatch swatches__btn-swatch"
              :style="{background: color.value}"
            >
              <span class="screenreader">{{ color.color }}</span>
            </div>
            <div class="swatch__active" />
          </div>
        </button>
      </li>
      <!-- Expand button - placed as a swatch item -->
      <li
        v-if="hasMoreSwatches && !isExpanded"
        class="swatches__item"
      >
        <button 
          class="swatches__btn btn-icon swatches__btn-expand"
          type="button"
          @click="toggleExpanded"
        >
          <i class="icon icon--plus" />
        </button>
      </li>
    </ul>
    
    <!-- Additional swatches section - shown when expanded -->
    <ul 
      v-if="isExpanded && hasMoreSwatches" 
      class="swatches list-reset product-item__swatches swatches--additional"
    >
      <li
        v-for="(color) in colors"
        :key="color.color"
        class="swatches__item js-product-swatch-wrapper"
        :class="{'is-selected': color.color === activeColor.color}"
      >
        <button
          class="swatches__btn btn-icon js-product-swatch product-item__swatch"
          :class="{ 'is-selected': color.color === activeColor.color }"
          :data-color="color.color"
          :data-image="color.featured_image?.src || missingImage"
          :data-id="color?.featured_image?.variant_ids ? color?.featured_image?.variant_ids[0] : ''"
          :data-hover="color?.hoverAsset || ''"
          :data-title="color?.title || ''"
          type="button"
          :aria-pressed="[color.color === activeColor.color ? 'true': 'false']"
          @click="updateImage"
          @mouseenter="onSwatchHover($event)"
          @mouseleave="handleMouseLeave($event)"
        >
          <div
            class="swatch"
            :class="{
              'swatch--border': color?.color?.toLowerCase().includes('white')
            }"
          >
            <span
              v-if="color.type === 'image'"
            >
              <ResponsiveImage
                :image="color.value"
                :imageAlt="color.color"
                modifier="swatch"
              />
            </span>
            <div
              v-else
              class="ir ir--swatch swatches__btn-swatch"
              :style="{background: color.value}"
            >
              <span class="screenreader">{{ color.color }}</span>
            </div>
            <div class="swatch__active" />
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  computed, 
  ref, 
  toRefs, 
  onMounted, 
  onUnmounted,
  nextTick
} from "vue";
import ResponsiveImage from "../common/responsive-image/ResponsiveImage";

export default {
  name: "ProductSwatches",
  components: {
    ResponsiveImage
  },
  props: {
    colors: {
      type: Array,
      required: true
    },
    activeColor: {
      type: Object,
      required: true
    },
    initialColor: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    noImage: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    updateImage: {
      type: Function,
      required: true
    },
    onSwatchHover: {
      type: Function,
      required: true
    },
    handleMouseLeave: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const {
      colors,
      noImage,
      initialColor,
      parent
    } = toRefs(props);
    const containerRef = ref(null);
    const limit = ref(9); // Start with 9 as default
    const totalSwatches = ref(colors?.value?.length || 0);
    const missingImage = noImage.value;
    const isExpanded = ref(false);
    const parentObserver = ref(null);

    // Main visible swatches
    const visibleSwatches = computed(() => {
      const productSwatches = colors.value;
      
      // Ensure we return at least some swatches
      if (!productSwatches || productSwatches.length === 0) {
        return [];
      }
      
      if (productSwatches.length > limit.value) {
        const slicedSwatches = productSwatches.slice(0, limit.value);
        const initColorValue = initialColor.value.color;
        const colorIndex = productSwatches.findIndex((swatch) => swatch.color === initColorValue);
        if (initColorValue && colorIndex >= limit.value) {
          // set initial color to be last visible swatch
          slicedSwatches[slicedSwatches.length - 1] = productSwatches[colorIndex];
        }
        return slicedSwatches;
      }

      return productSwatches;
    });

    // Additional swatches beyond the limit
    const additionalSwatches = computed(() => {
      if (colors.value?.length > limit.value) {
        return colors.value.slice(limit.value);
      }
      return [];
    });

    // Check if there are more swatches to show
    const hasMoreSwatches = computed(() => {
      return totalSwatches.value > limit.value;
    });

    // Count of remaining swatches
    const remainingSwatchesCount = computed(() => {
      return totalSwatches.value - limit.value;
    });

    // Toggle expand/collapse function
    const toggleExpanded = () => {
      const $wrapperEle = parent?.value?.parentElement || null;
      isExpanded.value = !isExpanded.value;

      if ($wrapperEle) { 
        if ($wrapperEle?.id?.includes("Carousel")) {
          nextTick(() => {
            const parentHeight = parent.value?.scrollHeight || 0;
            if (parentHeight > 0) {
              document.dispatchEvent(new CustomEvent("resizeFlickity", {
                detail: { 
                  height: parentHeight
                }
              }));
            }
          });
        }
      }
    };

    const calculateLimit = () => {
      if (!parent.value) {
        return;
      }

      if (isExpanded.value) {
        return;
      }

      const parentWidth = parent.value?.offsetWidth || 0;
      if (!parentWidth || parentWidth <= 0) {
        return;
      }
      
      // Fixed dimensions
      const activeSwatchWidth = 30;
      const swatchWidth = 24;
      const gapWidth = 16;
      const expandButtonWidth = 24;
      
      // We need to account for 1 active swatch and (N-1) regular swatches
      // For N elements total: 
      // 1*activeSwatchWidth + (N-1)*swatchWidth + (N-1)*gapWidth <= parentWidth
      
      // Rearranging to solve for N:
      // N = (parentWidth + swatchWidth + gapWidth - activeSwatchWidth) / (swatchWidth + gapWidth)
      const totalElementsCount = Math.floor(
        (parentWidth + swatchWidth + gapWidth - activeSwatchWidth) / (swatchWidth + gapWidth)
      );
      
      let maxVisible;
      if (colors.value && colors.value.length > totalElementsCount) {
        // Reserve one spot for button
        maxVisible = totalElementsCount - 1;
      } else {
        // No button needed
        maxVisible = totalElementsCount;
      }
      
      // ensure we have at least 1 visible swatch
      maxVisible = Math.max(1, maxVisible);
      
      if (colors.value && colors.value.length > maxVisible) {
        // Calculate the exact width needed with 1 active swatch, (maxVisible-1) regular swatches, and button
        const exactWidth = 
          activeSwatchWidth + 
          ((maxVisible - 1) * swatchWidth) + 
          expandButtonWidth + 
          (maxVisible * gapWidth);
          
        if (exactWidth > parentWidth) {
          maxVisible = Math.max(1, maxVisible - 1);
        }
        
        // Check our calculation with one more swatch
        if (maxVisible < colors.value.length - 1) {
          const widthWithOneMore = 
            activeSwatchWidth + 
            (maxVisible * swatchWidth) + 
            expandButtonWidth + 
            ((maxVisible + 1) * gapWidth);
            
          if (widthWithOneMore <= parentWidth) {
            maxVisible += 1;
          }
        }
      }
      
      // Cap at 9 maximum visible swatches
      maxVisible = Math.min(9, maxVisible);
      
      if (limit.value !== maxVisible) {
        limit.value = maxVisible;
      }
    };
    const handleResize = () => {
      // small timeout for resize event
      setTimeout(calculateLimit, 100);
    };

    onMounted(() => {
      // Initial calculation
      calculateLimit();
      
      // Try again after a short delay
      setTimeout(calculateLimit, 250);
      
      window.addEventListener("resize", handleResize);
      window.addEventListener("product-view-changed", calculateLimit);

      if (parent.value && window.ResizeObserver) {
        const resizeObserver = new ResizeObserver(() => {
          calculateLimit();
        });

        resizeObserver.observe(parent.value);

        // Store the observer so we can disconnect it later
        parentObserver.value = resizeObserver;
      }
    });
    
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("product-view-changed", calculateLimit);

      // Disconnect the ResizeObserver when component is unmounted
      if (parentObserver.value) {
        parentObserver.value.disconnect();
      }
    });

    return {
      containerRef,
      visibleSwatches,
      additionalSwatches,
      totalSwatches,
      hasMoreSwatches,
      remainingSwatchesCount,
      limit,
      missingImage,
      isExpanded,
      toggleExpanded
    };
  }
};
</script>

<style>
.swatches-wrapper {
  position: relative;
}

/* Animation for the additional swatches */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease;
  max-height: 500px;
}

.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>