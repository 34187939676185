import Flickity from 'flickity';

function collectionToolbar(opts) {
  const config = {
    dom: {
      toolbar: 'collectionToolbar',
      pinnedUpload: 'pinnedUpload',
      stickyMarker: '.js-sticky-collection-marker',
      collectionView: 'js-collection-view',
      productItemCarousel: 'js-product-item-carousel',
      productsGrid: 'productsGrid',
      header: 'header',
      searchTabs: 'searchTabs',
    },
    cls: {
      active: 'is-active',
      isPinned: 'is-pinned',
      hide: 'hide',
      flickityEnabled: 'flickity-enabled',
    },
    flickityOpts: {
      contain: true,
      cellAlign: 'center',
      adaptiveHeight: false,
      prevNextButtons: true,
      pageDots: true,
      draggable: true,
      dragThreshold: 10,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
      resize: true,
    },
    modifier: {
      productsPerRow: 'products-per-row--',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options
  const $productsGrid = document.getElementById(c.dom.productsGrid);
  const $toolbar = document.getElementById(c.dom.toolbar);
  const $stickyMarker = document.querySelector(c.dom.stickyMarker);
  const $productItemCarousels = document.querySelectorAll(`.${c.dom.productItemCarousel}`);
  const $uploadButton = document.getElementById(c.dom.pinnedUpload) || null;
  let view;

  /**
   * Init
   */
  function init() {
    if (!$productsGrid || !$toolbar) return;
    addEvents();
    responsive();
    __.windowResize(responsive);
    detectPinnedToolbar();
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: c.dom.toolbar,
      event: 'click',
      className: c.dom.collectionView,
      fn: handleViewChange,
    });
  }

  function handleViewChange(e) {
    const { currentTarget } = e;
    const gridClass = $productsGrid.classList;
    gridClass.forEach((className) => {
      if (className.includes(c.modifier.productsPerRow)) {
        __.removeClass($productsGrid, className);
      }
    });
    const $viewBtns = document.querySelectorAll(`.${c.dom.collectionView}`);
    $viewBtns.forEach((btn) => {
      __.removeClass(btn, c.cls.active);
      btn.removeAttribute('aria-label');
    });
    const { classModifier } = currentTarget.dataset;
    __.addClass($productsGrid, classModifier);
    __.addClass(currentTarget, c.cls.active);
    currentTarget.setAttribute('aria-label', `size ${classModifier.replace('products-per-row--', '')}`);

    if ($productItemCarousels) {
      $productItemCarousels.forEach(($carousel) => {
        // Reset Flickity if it is already enabled
        if (!__.hasClass($carousel, c.cls.flickityEnabled)) {
          return;
        }

        resetFlickity($carousel);
      });
    }

    window.dispatchEvent(new CustomEvent("product-view-changed"));
  }

  function detectPinnedToolbar() {
    if ($stickyMarker) {
      const observer = new IntersectionObserver(
        ([entries]) => {
          const boundingRect = entries.boundingClientRect;
          const isPinned = boundingRect.top < 0;
          $toolbar.classList.toggle(c.cls.isPinned, isPinned);
          if ($uploadButton) {
            $uploadButton.classList.toggle(c.cls.hide, !isPinned);
          }
        },
        { threshold: [1] },
      );

      observer.observe($stickyMarker);
    }
  }

  function resetFlickity($el) {
    const flkty = Flickity.data($el);

    if (flkty) {
      flkty.destroy();
    }

    setTimeout(() => {
      /* eslint-disable-next-line */
      new Flickity($el, c.dom.flickityOpts);
    }, 300);
  }

  /**
   * Responsive functions
   */
  function responsive() {
    const $viewBtns = document.querySelectorAll(`.${c.dom.collectionView}`);

    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          resetProductsPerRow();
          __.addClass($productsGrid, `${c.modifier.productsPerRow}four`);
        }
      },
    });

    if ($viewBtns) {
      __.mq({
        view: 'mobile',
        callback: () => {
          if (view !== 'mobile') {
            view = 'mobile';
            const defaultMobileGridSize = 'one';
            const defaultMobileGridSizeClass = `${c.modifier.productsPerRow}${defaultMobileGridSize}`;

            $viewBtns.forEach((btn) => {
              __.removeClass(btn, c.cls.active);

              if (btn.dataset.classModifier === defaultMobileGridSizeClass) {
                __.addClass(btn, c.cls.active);
              }
            });

            __.addClass($productsGrid, defaultMobileGridSizeClass);
            resetProductsPerRow();
          }
        },
      });
    }
  }

  function resetProductsPerRow() {
    const gridClass = document.getElementById(c.dom.productsGrid).classList;
    gridClass.forEach((className) => {
      if (className.includes(c.modifier.productsPerRow)) {
        __.removeClass($productsGrid, className);
      }
    });
  }

  return init();
}

export default collectionToolbar;
