/**
 * Use Media Query
 *
 * @param {String} query valid media query, ex: '(min-width: 1024px)'
 */
import { ref, onMounted, onUnmounted } from "vue";

export default function useMediaQuery(query) {
  const matches = ref(undefined);
  let mediaQuery = null;
  let listener = null;

  onMounted(() => {
    if (typeof window === "undefined") {return;}
    
    mediaQuery = window.matchMedia(query);
    matches.value = mediaQuery.matches;
    
    listener = (event) => {
      matches.value = event.matches;
    };
    
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener("change", listener);
    }
  });

  onUnmounted(() => {
    if (!mediaQuery || !listener) {return;}
    
    if (mediaQuery.removeEventListener) {
      mediaQuery.removeEventListener("change", listener);
    } else {
      mediaQuery.removeListener(listener);
    }
  });

  return matches;
}